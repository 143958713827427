<template>
  <div class="home"></div>
</template>

<script>
import { checkIn } from '@/api/api'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Home',
  async mounted() {
    let res = await checkIn()
    if (res) {
      this.SET_DOCTOR_INFO(res)
      this.SET_USER_ID(res.id)
      this.$router.replace({
        path: '/me'
      })
    } else {
      this.$router.replace({
        path: '/login'
      })
    }
  },
  methods: {
    ...mapActions(['SET_USER_ID', 'SET_DOCTOR_INFO'])
  }
}
</script>
